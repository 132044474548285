@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --green: #1dab4b;
  --white: #fff;
  --dark-midnight-blue: #0e102c;
  --lite-black: #070813;
  --yellow: #fada70;
  --dark-yellow: #e8b715;
  --dark-yellow-1: #edbd41;
  --dark-blue-grey: #12131d;
  --grey: #141416;
  --black: #000;
  --lite-grey-1: #f2f2f2;
  --lite-grey-2: #dadada;
  --lite-grey: #f6f7f8;
  --dark-grey: #363636;
  --blue-grey: #eef0f6;
  --dark-grey-1: #403c3e;

  --black-btn-text: #04060e;
  --red: #d04342;
  --text-gray: #7f7f7f;
}
body {
  font-family: "Poppins", sans-serif !important;
  background-color: #fff !important;
}
.fw-300 {
  font-weight: 300;
}
.navbar {
  position: fixed !important;
  width: 100% !important;
  z-index: 100;
  height: 84px;
  padding: 15px 0px;
  line-height: 39px;
}
.homepage {
  padding-top: 84px !important;
}
.redclr {
  color: #d04342;
  font-weight: 500;
}
.navbar {
  background: var(--lite-grey) 0% 0% no-repeat padding-box !important;
  /* border-bottom: 2px solid #242323; */
}
.navbar .navbar-brand {
  color: black !important;
}
.navbar a {
  padding: 8px 30px;
  text-decoration: none !important;
  color: var(--black) !important;
  transition: 0.5s;
  font-weight: 500;
}
.navbar a.active {
  /* color: var(--dark-yellow-1) !important; */
}
.navbar a:hover {
  color: var(--dark-yellow-1) !important;
  transition: 0.5s;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: url("./Assets/btn-icon.png") !important;
}
.connect_wallet_btn {
  font-weight: 600;
  height: 38px;
}
.green-btn {
  background: var(--green) !important;
  border: 1px solid var(--green) !important;
  border-radius: 8px;
  box-shadow: none !important;
  font-weight: 500;
}
.green-btn:hover {
  background: #fada70 !important;
  border: 1px solid #fada70 !important;
  color: black;
  border-radius: 8px;
  font-weight: 500;
}
.bordergreen-btn {
  background: transparent !important;
  border: 1px solid var(--green) !important;
  color: var(--green) !important;
}
.partnermarquee {
  display: flex;
  flex-direction: row;
  /* gap: 30px; */
}
.partnerwidth {
  width: 220px;
  /* height: 200px; */
}
.auto-trading {
  color: var(--green);
  font-weight: 600;
  font-size: 39px;
}
.titles {
  width: 100%;
  max-width: 70%;
  min-width: 70%;
  margin: auto;
  line-height: 55px;
  font-size: 35px !important;
}
.heading {
  color: var(--green);
  line-height: 42px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 32px;
}
.Our_partner {
  color: var(--dark-grey);
  font-weight: 600;
  font-size: 42px;
}
.sandbtn {
  background: var(--dark-yellow-1) !important;
  border: 1px solid #fada70;
  border-radius: 5px;
  color: var(--black-btn-text);
  font-weight: 600;
  padding: 5px 10px;
}
.sandbtn:hover {
  background: var(--green) !important;
  border: 1px solid var(--green) !important;
}
.sandbtn:focus {
  box-shadow: none !important;
  border: none;
  outline: none;
}
.bordersandbtn {
  background: var(--lite-grey);
  /* border: 1px solid #fada70; */
  color: var(--dark-grey);
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: 600;
}
.bordersandbtn:hover {
  /* border: 1px solid var(--green) !important; */
  color: var(--white) !important;
  background-color: var(--green);
}
.bordersandbtn:focus {
  box-shadow: none !important;
}
.bordersandbtn:active {
  border-color: unset !important;
}
.partnercard {
  background-color: var(--lite-grey);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 8px #00000029;
}
.position {
  position: relative;
  /* overflow: hidden; */
}
.leftimg {
  position: absolute;
  z-index: -1;
  top: 15%;
  max-width: 24%;
  left: 0px;
  opacity: 0.8;
}
.dot {
  background-color: var(--green);
  width: 12px;
  height: 12px;
  border-radius: 50px;
}
.rightimg {
  position: absolute;
  z-index: -1;
  top: 15%;
  max-width: 24%;
  right: 0px;
  opacity: 0.8;
}
.buytokenbox {
  background: var(--lite-grey);
  width: 100%;
  margin: auto;
  /* box-shadow: 0 1px 6px #1dab4b85; */
  border-radius: 10px;
  position: relative;
  border: 1px solid var(--green);
}
.progress {
  height: 14px !important;
  background-color: #272831 !important;
  border-radius: 18px !important;
}
.progress-bar {
  background-color: #4eab4c !important;
}
.grnclr {
  color: var(--green);
  font-weight: 600;
}
.earn_passive_btm_text {
  color: var(--dark-grey);
}
.our_core_value {
  color: var(--dark-grey);
}
.claim {
  background: #1a1c25;
  border-radius: 6px;
  padding: 20px 20px;
}
.max-btn {
  padding: 10px 30px;
}
.home-trade-with {
  color: var(--dark-grey);
  font-weight: normal;
}
.position svg {
  position: absolute;
  max-width: 12%;
  top: -42%;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0%) !important;
}
.position::after {
  background: #4eab4c;
  content: "";
  -webkit-filter: blur(50px);
  filter: blur(50px);
  height: 120px;
  right: 0;
  opacity: 0.3;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(178deg);
  transform: rotate(178deg);
  width: 120px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #4eab4c !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}
.rangeslider .rangeslider__handle {
  background: #4eab4c !important;
  border: 1px solid #4eab4c !important;
  box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  background-color: unset !important;
  box-shadow: unset !important;
}
.rangeslider {
  /* background: transparent !important; */
  background-color: #ebebec;
  box-shadow: none !important;
}
.infoclr {
  color: #4eab4c;
  margin-top: -3px;
}
.react-switch-bg {
  width: 60px !important;
  background: white !important;
  border: 1px solid green !important;
}

.react-switch-handle {
  left: 4px;
  height: 20px !important;
  width: 20px !important;
  top: 4px !important;
  background-color: var(--green) !important;
  box-shadow: none !important;
}
.simulation .form-control {
  color: var(--dark-grey);
  background-color: #ebebeb;
  height: 50px;
  /* border: 1px solid #1a1b25; */
  box-shadow: none;
  border: none;
}
.navbar-light .navbar-toggler {
  border: none !important;
}
.navbar-light .navbar-toggler:focus {
  border: none !important;
  box-shadow: none !important;
}
.simulation .form-control:focus {
  /* border: 1px solid #ced4da; */
  border-right: 0px !important;
  border-color: #ced4da;
}
.simulation .btn-secondary {
  color: var(--dark-grey);
  background-color: #ebebeb;
  font-weight: 300;
  border: none !important;
  font-size: 15px;
}
.estimate_average {
  color: var(--dark-grey);
  font-size: 18px;
}

.simulation .btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 0%);
}

.simulation .dropdown-item {
  color: var(--dark-grey);
}
.simulation .dropdown-item:hover {
  background-color: #4eab4c;
}
.simulation .buytokenbox {
  position: relative;
  z-index: 6;
}
.bitcoin {
  position: absolute;
  max-width: 9%;
  right: -18px;
  top: -32px;
  width: 50px;
  height: 50px;
}
.blucoin {
  position: absolute;
  max-width: 10%;
  z-index: -1;
}
.automatic .card {
  background: #eef0f6;
  border-radius: 24px;
}
.automatic .card-body {
  background: var(--green);
  border-radius: 0px 0px 8px 8px;
}
.automatic .card-img-top {
  width: 100%;
  max-width: 46%;
  min-width: 46%;
  margin: auto;
  margin-top: 20px;
}
.path {
  width: 100%;
  max-width: 70%;
  min-width: 70%;
  margin: auto;
  position: relative;
  z-index: 6;
}
.boxes {
  width: 100%;
  /* max-width: 80%;
  min-width: 80%; */
  margin: auto;
}
.pathimg {
  position: absolute;
  z-index: -1;
  top: 20%;
}
.joins {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spaceimg {
  width: 62%;
}
.joins img {
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.joins_content {
  margin-top: 50px;
}
.joins_title {
  text-transform: uppercase;
  font-size: 32px;
  color: var(--dark-yellow-1);
  font-weight: 600;
}
.joins_subtitle {
  margin-top: 20px;
  color: var(--dark-grey);
  font-size: 16px;
}
.our_core_value_bld {
  font-weight: 700 !important;
}
/* Our Team */

.our_team {
  background: url("../src/Assets/Img/line.png");
  background-size: 100% 100%;
}
/* .dash_board .left_side_sec .sidebar_sec .home_text {
  color: var(--black);
  background-color: transparent;
}

.dash_board .left_side_sec .sidebar_sec .home_text::before {
  box-shadow: none !important;
}
.dash_board .left_side_sec .sidebar_sec .home_text::after {
  box-shadow: none !important;
} */

.trade-history .left_side_sec .sidebar_sec .home_text {
  color: var(--black);
  background-color: transparent;
}
.trade-history .left_side_sec .sidebar_sec .home_text::before {
  box-shadow: none !important;
}
.trade-history .left_side_sec .sidebar_sec .home_text::after {
  box-shadow: none !important;
}

.lite_grey_clr {
  color: var(--dark-grey);
  font-weight: 500;
}
.homepage .our_team .tit {
  font-weight: 450;
}
.auto_tradings_bg {
  background-color: #f6f6f6;
}

.spinner-item {
  border: 1px solid red;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  position: absolute;
  left: 50%;
  /* top: 58%; */
  transform: translate(-50%, 50%);
  opacity: 0;
  animation: zoom 2s linear 0.1s infinite;
}
.green_clr .spinner-item {
  border: 1px solid var(--green);
}

.spinner-item--2 {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}
.spinner-item--3 {
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

@-webkit-keyframes zoom {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes zoom {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.red_circle {
  /* position: absolute; */
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  top: -14px;
  left: 111px;
}
.green_circle {
  /* position: absolute; */
  width: 12px;
  height: 12px;
  background-color: var(--green);
  border-radius: 50%;
  top: -14px;
  left: 111px;
}

.green_leftBot .spinner-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, +50%);
}
.green_topRight .spinner-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, +50%);
}
.no_white_space {
  white-space: nowrap;
  text-align: center;
}

.homepage .no_white_space .straight_line {
  width: 1px;
  height: 130px;
  background-color: red;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.homepage .our_team .employee:hover {
  /* background: url("../src/Assets/circle.gif") center center; */
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
.green_Clr_txt {
  color: var(--green);
}

.homepage .our_team .employee:hover .no_white_space {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  /* margin-top: 10px; */
  left: 50%;
  right: 50%;
}

.homepage .our_team .employee:hover .hover_container {
  display: flex !important;
}

.new_col {
  flex-direction: column !important;
}
.lusia_center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.homepage .straight_line {
  width: 1px;
  height: 130px;
  background-color: red;
  display: flex;
  align-content: center;
  flex-direction: column;
}

/* Subscribe */

.homepage .subscribe .content_wrapper .custom_input {
  background: var(--white);
  border: none;
  outline: none;
  color: var(--dark-grey);
  font-size: 12px;
}
.homepage .subscribe .content_wrapper .custom_input::placeholder {
  color: #cfcfcf;
  font-size: 20px;
}
.homepage .subscribe .start_btn {
  padding: 15px 20px !important;
  width: fit-content !important;
  border: none;
  outline: none;
  background-color: #edbd41;
  font-weight: 600;
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}
.homepage .subscribe .start_btn:hover {
  background-color: green;
  color: white !important;
}
.homepage .subscribe .shape {
  bottom: 0%;
  right: 0%;
}
.homepage .subscribe {
  background-color: #f6f6f6 !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  margin-top: 50px !important;
  overflow: hidden !important;
}

/* Footer */

.homepage .footerr .margin_top {
  border-top: 1px solid #f6f6f6;
}
.homepage .footerr .react_icons {
  font-size: 30px;
  color: var(--dark-grey);
  transition: 0.3s;
}
.homepage .footerr .react_icons:hover {
  color: var(--dark-yellow-1);
  cursor: pointer;
}

/* bot trades */

.homepage .bot_trades {
  padding-top: 80px;
  padding-bottom: 80px;
}
.homepage .bot_trades .profit_wraper {
  background: var(--lite-grey);
  box-shadow: 0px 0px 6px #1c9c47a1;
  border-radius: 10px;
  width: fit-content;
}
.homepage .bot_trades .profit_wraper .img_border {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid green;
}
.homepage .bot_trades .profit_wraper .profitP {
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
  color: var(--dark-grey);
}
.homepage .bot_trades .profit_wraper .profit_subP {
  font-size: 8px;
  color: green;
  margin-bottom: 0px !important;
}
.homepage .bot_trades .profit_wraper .profit_subP span {
  font-weight: 700;
  font-size: 16px;
  color: var(--green);
}
.card_hover:hover {
  box-shadow: 0 1px 6px #1dab4b85;
}
.card_bt:hover {
  background-color: #fada70 !important;
  cursor: pointer;
}
.fw-500 {
  font-weight: 500 !important;
}

/* Media Query */

@media (min-width: 320px) {
  .joins_title {
    font-size: 26px;
  }
  .homepage .our_team .lusia.large_screen {
    position: relative !important;
  }
  .homepage .our_team img {
    position: relative !important;
  }
  .homepage .our_team .maria.large_screen {
    position: relative !important;
  }
  .homepage .our_team .gabrial.large_screen {
    position: relative !important;
  }
  .homepage .our_team .barister.large_screen {
    position: relative !important;
  }
  .homepage .our_team .gloria.large_screen {
    position: relative !important;
  }
  .homepage .our_team .employee .mob_content_open {
    position: relative !important;
  }

  .homepage .our_team .green_leftBot {
    bottom: 9.3%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.9%;
  }
  .homepage .footerr {
    /* padding-top: 100px; */
  }
  /* .homepage .trade_wraper .first_trade_col {
    margin-left: -11%;
    margin-top: -7%;
  }
  .homepage .trade_wraper .second_trade_col {
    margin-left: 10% !important;
    margin-top: -7% !important;
  } */

  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -50%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: -2%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 50%;
  }
  .homepage .bot_trades .profit_wraper .img_border {
    width: 25px;
    height: 25px;
  }
  .homepage .bot_trades .profit_wraper {
    padding: 5px !important;
    border-radius: 5px;
    gap: 5px !important;
  }
  .homepage .bot_trades .profit_wraper .profitP {
    font-size: 5px;
    white-space: nowrap;
  }
  .homepage .bot_trades .profit_wraper .profit_subP {
    font-size: 5px;
    display: flex;
    align-items: center;
  }
  .homepage .bot_trades .profit_wraper .profit_subP span {
    font-size: 8px;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -10%;
  }
  .homepage .bot_trades .second_col_div {
    position: relative;
    left: 0%;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 10%;
  }
  .buytokenbox {
    padding: 19px;
  }
  .sandbtn {
    font-size: 15px;
    padding: 10px 25px;
  }
  .bordersandbtn {
    font-size: 15px;

    padding: 12px 25px;
  }
  .custom_padding {
    padding-top: 80px !important;
    padding-bottom: 40px !important;
  }
  .partnerwidth {
    width: 120px;
    /* height: 200px; */
  }
  .blucoin {
    left: -11px;
    bottom: 25px;
  }
  .img_width {
    width: 37%;
  }
  .position svg {
    position: absolute;
    max-width: 70%;
    top: -47%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
  .homepage .employee .hover_container p {
    font-size: 12px !important;
    white-space: nowrap !important;
  }
  .bot_left {
    z-index: -1;
    top: 25%;
    left: 0;
    opacity: 0.8;
  }
  .bot_right {
    z-index: -1;
    top: 25%;
    right: 0;
    opacity: 0.8;
  }
}

@media (min-width: 576px) {
  .homepage .bot_trades .profit_wraper .img_border {
    width: 40px;
    height: 40px;
  }
  .homepage .bot_trades .profit_wraper {
    padding: 10px !important;
    border-radius: 10px;
    gap: 10px !important;
  }
  .homepage .bot_trades .profit_wraper .profitP {
    font-size: 15px;
    white-space: nowrap;
  }
  .homepage .bot_trades .profit_wraper .profit_subP {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .homepage .bot_trades .profit_wraper .profit_subP span {
    font-size: 18px;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -10%;
  }
  .homepage .bot_trades .second_col_div {
    position: relative;
    left: 0%;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 10%;
  }
  .buytokenbox {
    padding: 40px;
  }
  .blucoin {
    left: -42px;
    bottom: 25px;
  }
  .position svg {
    position: absolute;
    max-width: 42%;
    top: -48%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
}
@media (min-width: 768px) {
  .joins_content {
    padding-left: 80px;
    padding-right: 80px;
  }
  .homepage .our_team .lusia.large_screen {
    position: absolute !important;
    bottom: 22%;
    left: 17%;
  }
  .homepage .our_team .maria.large_screen {
    position: absolute !important;
    bottom: 34%;
    left: 31%;
  }
  .homepage .our_team .gabrial.large_screen {
    position: absolute !important;
    bottom: 42%;
    left: 49%;
  }
  .homepage .our_team .barister.large_screen {
    position: absolute !important;
    bottom: 47%;
    left: 70%;
  }
  .homepage .our_team .gloria.large_screen {
    position: absolute !important;
    bottom: 60%;
    left: 83%;
  }
  .homepage .our_team .employee .mob_content_open {
    position: absolute !important;
  }

  .homepage .our_team .green_leftBot {
    bottom: 9.3%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.6%;
  }
  .buytokenbox {
    padding: 28px;
  }
  .position svg {
    position: absolute;
    max-width: 28%;
    top: -46%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
  .our_team {
    min-height: 100vh;
  }
}
@media (min-width: 992px) {
  .joins_content {
    padding-left: 26px;
    padding-right: 26px;
  }
  .homepage .our_team .lusia.large_screen {
    bottom: 21%;
    left: 19%;
  }
  .homepage .our_team .maria.large_screen {
    bottom: 33%;
    left: 33%;
  }
  .homepage .our_team .gabrial.large_screen {
    bottom: 43%;
    left: 48%;
  }
  .homepage .our_team .barister.large_screen {
    bottom: 44%;
    left: 65%;
  }
  .homepage .our_team .gloria.large_screen {
    bottom: 53%;
    left: 81%;
  }

  .homepage .our_team .green_leftBot {
    bottom: 9.3%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.6%;
  }
  .homepage .subscribe {
    background-color: #12121d;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    margin-top: 110px !important;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -24%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: 47%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 130%;
  }
  .tradeCircleImg svg {
    width: unset !important;
    height: unset !important;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -38%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: -2%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 50%;
  }
  .homepage .bot_trades .profit_wraper .img_border {
    width: 50px;
    height: 50px;
  }
  .homepage .bot_trades .profit_wraper {
    padding: 15px 28px !important;
    border-radius: 15px;
    gap: 28px !important;
  }
  .homepage .bot_trades .profit_wraper .profitP {
    font-size: 17px;
    white-space: nowrap;
  }
  .homepage .bot_trades .profit_wraper .profit_subP {
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .homepage .bot_trades .profit_wraper .profit_subP span {
    font-size: 18px;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -22%;
  }
  .homepage .bot_trades .second_col_div {
    position: relative;
    left: 0%;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 23%;
  }
  .img_width {
    width: 37%;
  }
  .position svg {
    position: absolute;
    max-width: 22%;
    top: -46%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
  .pathimg {
    width: 82%;
    left: 10%;
  }
}
@media (min-width: 1200px) {
  .joins_title {
    font-size: 30px;
  }
  .homepage .our_team img {
    width: 100%;
  }

  .homepage .our_team .lusia.large_screen {
    bottom: 21%;
    left: 19%;
  }
  .homepage .our_team .maria.large_screen {
    bottom: 33%;
    left: 33%;
  }
  .homepage .our_team .gabrial.large_screen {
    bottom: 43%;
    left: 48%;
  }
  .homepage .our_team .barister.large_screen {
    bottom: 44%;
    left: 65%;
  }
  .homepage .our_team .gloria.large_screen {
    bottom: 53%;
    left: 81%;
  }

  .homepage .our_team .green_leftBot {
    bottom: 9.3%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.6%;
  }
  /* .homepage .employee .hover_container h4,
  .homepage .employee .hover_container p {
    white-space: nowrap !important;
  } */
  .homepage .footerr {
    /* padding-top: 150px; */
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -24%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: -3%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 35%;
  }
  .passive_img {
    width: 80% !important;
    text-align: right !important;
  }
  .passive_div {
    text-align: right;
  }
  .passive_div2 {
    text-align: left;
  }
  .custom_padding {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .secureBot_img {
    width: 85% !important;
  }
  .compound_img {
    width: 85% !important;
  }
  .homepage .bot_trades {
    min-height: 100vh;
  }
  .buytokenbox {
    max-width: 65%;
    min-width: 65%;
  }
  .partnerwidth {
    width: 220px;
    /* height: 200px; */
  }

  .position svg {
    position: absolute;
    max-width: 20%;
    top: -44%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
  .pathimg {
    width: 82%;
    left: 10%;
  }
}
@media (min-width: 1441px) {
  .homepage .our_team .lusia.large_screen {
    bottom: 21%;
    left: 19%;
  }
  .homepage .our_team .maria.large_screen {
    bottom: 33%;
    left: 33%;
  }
  .homepage .our_team .gabrial.large_screen {
    bottom: 43%;
    left: 48%;
  }
  .homepage .our_team .barister.large_screen {
    bottom: 44%;
    left: 65%;
  }
  .homepage .our_team .gloria.large_screen {
    bottom: 53%;
    left: 81%;
  }

  .homepage .our_team .green_leftBot {
    bottom: 9.3%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.9%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -24%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: -3%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 25%;
  }
  .position svg {
    position: absolute;
    max-width: 17%;
    top: -41%;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0%) !important;
  }
  .buytokenbox .custom_padding {
    padding-top: 118px !important;
  }
  .pathimg {
    width: 82%;
    left: 10%;
  }
}
@media (min-width: 1920px) {
  .homepage .our_team .lusia.large_screen {
    bottom: 21%;
    left: 19%;
  }
  .homepage .our_team .maria.large_screen {
    bottom: 33%;
    left: 33%;
  }
  .homepage .our_team .gabrial.large_screen {
    bottom: 43%;
    left: 48%;
  }
  .homepage .our_team .barister.large_screen {
    bottom: 44%;
    left: 65%;
  }
  .homepage .our_team .gloria.large_screen {
    bottom: 53%;
    left: 81%;
  }
  .no_white_space {
    /* top: 235px;
    left: -62px; */
  }

  .homepage .our_team .green_leftBot {
    bottom: 9%;
    left: 11%;
  }
  .homepage .our_team .green_topRight {
    top: 14%;
    right: 7.9%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -24%;
  }
  .homepage .bot_trades .trade_wraper .second_trade_col .div_center {
    left: -3%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 27%;
  }
  .secureBot_img {
    width: 90% !important;
  }
}

@media (max-width: 575px) {
  .flex_btn_div,
  .flex_mobi_not {
    display: block !important;
  }
  .flex_mobi_not button {
    padding-left: 0px !important;
    margin-top: 10px !important;
  }
  .titles br {
    display: none !important;
  }
  .homepage .employee .hover_container p {
    font-size: 12px !important;
    white-space: nowrap !important;
  }
}
@media (max-width: 767px) {
  /* .homepage .employee .hover_container h4,
  .homepage .employee .hover_container p {
    white-space: wrap !important;
  } */
}
@media (max-width: 991px) {
  /* .mob_view {
    position: fixed;
    background: var(--white);
    width: 100%;
    top: 84px;
    left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 2px var(--black);
  } */
  .homepage .subscribe .start_btn {
    margin: auto;
  }
}
@media (max-width: 1199px) {
  .titles {
    min-width: none;
    max-width: none;
  }

  .mob_btn {
    width: fit-content !important;
  }
  .homepage .employee .hover_container h4 {
    font-size: 16px !important;
    white-space: nowrap !important;
  }
  .navbar_tog {
    margin-left: auto !important;
  }
  .mob_view {
    position: fixed;
    background: var(--white);
    width: 100%;
    top: 69px;
    left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 2px var(--black);
  }
}
.headings {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
}
@media (319px < width < 769px) {
  .headings {
    font-size: 20px !important;
    /* font-weight: 550; */
  }
  .homepage .subscribe .content_wrapper .custom_input {
    font-size: 14px;
  }
  .homepage .subscribe .content_wrapper .custom_input::placeholder {
    font-size: 14px;
  }
  .homepage .subscribe .start_btn {
    padding: 8px 15px !important;
    font-size: 15px !important;
  }
}
@media (767px < width < 992px) {
  .homepage .employee .hover_container p {
    font-size: 8px !important;
  }
}
@media (991px < width < 1201px) {
  .homepage .employee .hover_container h4,
  .homepage .employee .hover_container p {
    white-space: nowrap !important;
  }
  .homepage .employee .hover_container h4 {
    font-size: 14px !important;
  }
  .homepage .employee .hover_container p {
    font-size: 11px !important;
  }
}

.pathimg {
  max-width: 100% !important;
}

@media (max-width: 480px) {
  .heading {
    line-height: 31px;
    font-size: 16px;
  }
  .headings {
    font-size: 22px !important;
  }
  .sandbtn {
    font-size: 13px;
    padding: 8px 20px;
  }
  .bordersandbtn {
    font-size: 13px;
    padding: 10px 20px;
  }
  .titles {
    line-height: 30px;
  }
  .lite_grey_clr {
    font-size: 13px;
  }
  .auto-trading {
    font-size: 28px;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 7%;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -7%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -47%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 47%;
  }
  .homepage .bot_trades {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .joins_content {
    margin-top: 30px;
  }
  .bitcoin {
    right: -11px;
    max-width: unset;
  }
  .earn_passive_btm_text {
    font-size: 13px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .heading {
    line-height: 31px;
    font-size: 22px;
  }
  .sandbtn {
    font-size: 15px;
    padding: 8px 20px;
  }
  .bordersandbtn {
    font-size: 15px;
    padding: 10px 20px;
  }
  .titles {
    line-height: 30px;
  }
  .lite_grey_clr {
    font-size: 14px;
  }
  .auto-trading {
    font-size: 30px;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 5%;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -5%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -31%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 31%;
  }
  .homepage .bot_trades {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .joins_content {
    margin-top: 30px;
  }
  .bitcoin {
    right: -11px;
    max-width: unset;
  }
  .earn_passive_btm_text {
    font-size: 13px;
  }
  .homepage .bot_trades .profit_wraper .profitP {
    font-size: 10px;
  }
  .homepage .bot_trades .profit_wraper .profit_subP span {
    font-size: 10px;
  }
  .homepage .bot_trades .profit_wraper .profit_subP {
    font-size: 10px;
  }
  .homepage .bot_trades .profit_wraper .img_border {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .heading {
    line-height: 33px;
    font-size: 26px !important;
  }

  .headings {
    font-size: 26px !important;
  }
  .sandbtn {
    font-size: 16px;
    padding: 8px 25px;
  }
  .bordersandbtn {
    font-size: 16px;
    padding: 10px 25px;
  }
  .titles {
    line-height: 30px;
  }
  .lite_grey_clr {
    font-size: 14px;
  }
  .auto-trading {
    font-size: 30px;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 5%;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -5%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -31%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 31%;
  }
  .homepage .bot_trades {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .joins_content {
    margin-top: 30px;
  }
  .bitcoin {
    right: -11px;
    max-width: unset;
  }
  .earn_passive_btm_text {
    font-size: 13px;
  }
  .homepage .subscribe .start_btn {
    padding: 10px 20px !important;
    font-size: 16px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .heading {
    line-height: 33px;
    font-size: 21px !important;
  }
  .headings {
    font-size: 26px !important;
  }
  .sandbtn {
    font-size: 15px;
    padding: 8px 25px;
  }
  .bordersandbtn {
    font-size: 15px;
    padding: 10px 25px;
  }
  .titles {
    line-height: 30px;
  }
  .lite_grey_clr {
    font-size: 14px;
  }
  .auto-trading {
    font-size: 30px;
  }
  .homepage .bot_trades .third_col_div {
    position: relative;
    left: 5%;
  }
  .homepage .bot_trades .fisrt_col_div {
    position: relative;
    left: -5%;
  }
  .homepage .bot_trades .trade_wraper .first_trade_col .div_center {
    left: -31%;
  }
  .homepage .bot_trades .trade_wraper .third_trade_col .div_center {
    left: 31%;
  }
  .homepage .bot_trades {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .joins_content {
    margin-top: 30px;
  }
  .bitcoin {
    right: -11px;
    max-width: unset;
  }
  .earn_passive_btm_text {
    font-size: 13px;
  }
  .homepage .subscribe .start_btn {
    padding: 10px 20px !important;
    font-size: 16px !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .heading {
    line-height: 33px;
    font-size: 25px !important;
  }
}
@media (min-width: 1200px) {
  .homepage .bot_trades .fisrt_col_div {
    left: -9% !important;
  }
  .homepage .bot_trades .third_col_div {
    left: 9%;
  }
}
@media (max-width: 1200px) {
  .navbar {
    height: unset !important;
  }
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 50%; */
  border: 3px solid transparent;
  z-index: 1001;

  overflow-y: auto;
  /* height: 100%; */
}

/* #loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;

  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
} */

/* #loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;

  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
} */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: white;
  /* background: #FFD64C; */
  /* background: url(./Assets/Img/Loading_bg.png); */
  /* background-size: 100% 100%; */
  z-index: 1000;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* / Loaded / */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
.auto_withdrawal {
  color: var(--dark-yellow-1);
  font-weight: 600;
}

.navbar_true .navbar {
  display: block !important;
}
.navbar_false .navbar {
  display: none !important;
}

.homecms_content h3 {
  color: var(--green);
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
}

.partner h2 {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
}

.ipo_cms_cont h2 {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
}
.ipo_cms_cont p {
  color: var(--dark-grey);
  font-weight: 500;
}
.buybot_cms h2 {
  font-size: 35px !important;
  line-height: 55px;
  margin: auto;
  max-width: 70%;
  min-width: 70%;
  width: 100%;
  color: var(--green);
  font-weight: 600;
  text-align: center;
}
.autotrading_cms h2 {
  color: var(--green);
  font-size: 39px;
  font-weight: 600;
  position: relative;
}
.autotrading_cms h2 {
  max-width: max-content;
}
.autotrading_cms h2:after {
  content: "";
  position: absolute;
  background-color: var(--green);
  border-radius: 50px;
  height: 12px;
  width: 12px;
  right: -24px;
  top: 20px;
}

.autotrading_cms p {
  color: var(--dark-yellow-1);
  font-weight: 600;
}
.automatic_bto_xms h2 {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
}
.erason_csm_conrt h2 {
  font-size: 35px !important;
  line-height: 55px;
  margin: auto;
  max-width: 70%;
  min-width: 70%;
  width: 100%;
  color: var(--green);
  font-weight: 600;
  text-align: center;
}
.erason_csm_conrt .joins_content h3 {
  font-size: 30px;
  color: var(--dark-yellow-1);
  font-weight: 600;
  text-transform: uppercase;
}
.profit_cms_cont h2 {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}

.trade_cnmt_cms h2 {
  font-size: 35px !important;
  line-height: 55px;
  margin: auto;
  max-width: 70%;
  min-width: 70%;
  width: 100%;
  color: var(--green);
  font-weight: 600;
  text-align: center;
}
.passin_onc_cms h3 {
  color: var(--green);
  font-size: 42px;
  font-weight: 600;
  text-transform: capitalize !important;
  margin-bottom: 20px;
}
.passin_onc_cms p {
  color: var(--dark-grey);
  font-weight: 500 !important;
}

.passin_onc_cms p.grnclr {
  color: var(--green) !important;
  font-weight: 600;
}
.srrta_arb_cms h2 {
  color: var(--green);
  font-weight: 600;
  font-size: 35px !important;
  line-height: 55px;
  margin: auto;
  max-width: 70%;
  min-width: 70%;
  width: 100%;
  text-align: center;
}
.srrta_arb_cms p {
  font-weight: 500 !important;
  color: var(--dark-grey);
  text-align: center;
  margin-top: 20px;
}
.meen_cnt_cms h2 {
  font-weight: 450;
  color: var(--green);
  font-size: 35px !important;
  line-height: 55px;
  margin: auto;
  max-width: 70%;
  min-width: 70%;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.meen_cnt_cms p {
  color: var(--dark-grey);
  font-weight: 500;
  text-align: center;
}
.subscribe_sec_cms h2 {
  letter-spacing: 1px;
  color: var(--green);
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
}
.automatic_bto_xms h2 {
  text-align: center;
}
.loader_sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_loader {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 45px;
  height: 45px;
  border: 5px solid #1dab4b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

/* Media query */

@media (max-width: 480px) {
  .homecms_content h3 {
    font-size: 16px;
    line-height: 31px;
  }
  .homecms_content .partner h2,
  .ipo_cms_cont h2,
  .buybot_cms h2,
  .automatic_bto_xms h2,
  .erason_csm_conrt h2,
  .profit_cms_cont h2,
  .trade_cnmt_cms h2,
  .homecms_content h3,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    font-size: 22px !important;
  }
  .ipo_cms_cont p,
  .passin_onc_cms p,
  .meen_cnt_cms p {
    font-size: 13px !important;
  }
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .srrta_arb_cms h2 {
    line-height: 30px;
  }
  .autotrading_cms h2 {
    font-size: 28px !important;
  }
  .autotrading_cms h2:after {
    top: 12px !important;
  }
  .erason_csm_conrt .joins_content h3 {
    font-size: 26px !important;
  }
  .subscribe_sec_cms h2 {
    font-size: 16px;
    line-height: 31px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .homecms_content h3 {
    font-size: 16px;
    line-height: 31px;
  }
  .homecms_content .partner h2,
  .ipo_cms_cont h2,
  .buybot_cms h2,
  .automatic_bto_xms h2,
  .erason_csm_conrt h2,
  .profit_cms_cont h2,
  .trade_cnmt_cms h2,
  .homecms_content h3,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    font-size: 22px !important;
  }
  .ipo_cms_cont p,
  .passin_onc_cms p,
  .meen_cnt_cms p {
    font-size: 13px !important;
  }
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .srrta_arb_cms h2 {
    line-height: 30px;
  }
  .autotrading_cms h2 {
    font-size: 28px !important;
  }
  .autotrading_cms h2:after {
    top: 12px !important;
  }
  .erason_csm_conrt .joins_content h3 {
    font-size: 26px !important;
  }
  .subscribe_sec_cms h2 {
    font-size: 16px;
    line-height: 31px;
  }
}

@media (min-width: 481px) and (max-width: 1199px) {
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2 {
    max-width: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .homecms_content h3 {
    font-size: 26px !important;
  }
  .partner h2,
  .ipo_cms_cont h2,
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .autotrading_cms h2,
  .automatic_bto_xms h2,
  .profit_cms_cont h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    font-size: 26px !important;
  }
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .automatic_bto_xms h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    line-height: 30px !important;
  }
  .autotrading_cms h2:after {
    right: -23px;
    top: 10px;
  }
  .meen_cnt_cms p {
    font-size: 14px;
  }
  .subscribe_sec_cms h2 {
    font-size: 26px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .homecms_content h3 {
    font-size: 21px !important;
    line-height: 33px;
  }
  .partner h2,
  .ipo_cms_cont h2,
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .autotrading_cms h2,
  .automatic_bto_xms h2,
  .profit_cms_cont h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    font-size: 26px !important;
  }
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .automatic_bto_xms h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2 {
    line-height: 30px !important;
  }
  .autotrading_cms h2:after {
    right: -23px;
    top: 10px;
  }
  .meen_cnt_cms p {
    font-size: 14px;
  }
  .subscribe_sec_cms h2 {
    font-size: 26px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .homecms_content h3 {
    font-size: 25px !important;
    line-height: 33px;
  }
  .partner h2,
  .ipo_cms_cont h2,
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .autotrading_cms h2,
  .automatic_bto_xms h2,
  .profit_cms_cont h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2,
  .homecms_content h3 {
    font-size: 40px !important;
  }
  .buybot_cms h2,
  .erason_csm_conrt h2,
  .trade_cnmt_cms h2,
  .automatic_bto_xms h2,
  .srrta_arb_cms h2,
  .meen_cnt_cms h2,
  .homecms_content h3 {
    line-height: 50px !important;
  }
  .autotrading_cms h2:after {
    right: -23px;
    top: 10px;
  }
  .meen_cnt_cms p {
    font-size: 14px;
  }
  .subscribe_sec_cms h2 {
    font-size: 26px !important;
  }
}
.mob_view_new {
  justify-content: right;
}
.just_cont_end_nav {
  justify-content: flex-end;
}

@media only screen and (max-width: 1199px) {
  .just_cont_end_nav {
    justify-content: center;
  }
  .btn_logout_nr {
    text-align: center;
  }

  .shadown_drop_menu .dropdown-item {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .shadown_drop_menu {
    left: unset !important;
    right: 0px !important;
  }
}

.btn_logout_nr .user_Logo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* border-radius: 50%;
  padding: 11px;
  width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; */
}

.btn_logout_nr .dropdown-toggle::after {
  content: none !important;
}
.shadown_drop_menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin-top: 10px;
}

.shadown_drop_menu .dropdown-item {
  padding: 0rem 1rem !important;
  font-weight: 400 !important;
}
.shadown_drop_menu .dropdown-item:hover {
  color: #000 !important;
}

.shadown_drop_menu .dropdown-item:focus,
.shadown_drop_menu .dropdown-item:hover,
.shadown_drop_menu .dropdown-item:focus-visible,
.shadown_drop_menu .dropdown-item:active {
  color: #1e2125;
  background-color: #e9ecef;
}
.affiliate_link_btn {
  background: transparent !important;
  border: none !important;
  color: var(--green) !important;
}
.affiliate_link_btn:hover {
  color: #000 !important;
}
.modal_popup_card.card {
  border: none !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
}

.validErrors_style {
  color: red;
  font-size: 13px;
}

.afilaite_card {
  background-image: linear-gradient(
    70deg,
    #1ca64a 40%,
    #107648 50%,
    #064b47 90%
  );
}

.aff_link {
  color: #fff;
  margin-bottom: 5px !important;
}
.aff_link_bold {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px !important;
}
.copy_icon {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
}

.flex_btn_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.afilaite_card {
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.form-control:focus,
.form-control:hover,
.form-control:focus-visible {
  border-color: #ced4da !important;
}

.notification_link_italic {
  color: var(--green);
  font-style: italic;
  font-size: 14px;
  display: block;
}
.notification_link_italic:hover {
  color: #d04342;
}

.border_div_noti {
  border: 1px solid var(--lite-grey-1);
}

.newnotify li {
  list-style-type: none;
  padding: 15px 5px 12px 15px;
  margin-bottom: 10px;
  border-left: 5px solid var(--green);
}

.newnotify li .text-muted,
.newnotify li.unread .lighttxt {
  color: #000;
  font-size: 12px;
}

.newnotify li .text-muted,
.newnotify li.read .lighttxt {
  color: #ccc !important;
  font-size: 12px;
}
.unread button {
  color: var(--green) !important;
  font-size: 12px !important;
}
.unread button:hover {
  color: #d04342 !important;
}
.unread button:hover svg {
  color: #d04342 !important;
  fill: #d04342 !important;
}
.newnotify li svg {
  color: var(--green) !important;
  fill: var(--green) !important;
  width: 13px;
  margin-right: 3px;
}

.newnotify li.read button {
  color: #ccc !important;
  font-size: 12px !important;
}

.newnotify li.read svg {
  color: #ccc !important;
  fill: #ccc !important;
}
.flex_mobi_not {
  justify-content: space-between;
}
.list_chet {
  border: 1px solid var(--lite-grey-1);
  margin: 10px;
}

.unread.list_chet {
  border: 1px solid var(--lite-grey-1) !important;
}

.read.list_chet {
  border: 1px solid #ccc !important;
}

.newnotify li.read {
  border-left: 3px solid #ccc !important;
}
.newnotify li.unread {
  border-left: 3px solid var(--green) !important;
}
.bnt_down
{
  display: block;
  width: 100% !important;
}